.pageContainer {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
  min-width: 100vw;
  min-height: 100vh;
}
.cardBody {
  display: flex;
  gap: 30px;
  align-items: center;
}
