.landingPageContainer {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
  width: 100vw;
  height: 100vh;
}

.landingPageContent {
  top: 160px;
  position: relative;
  margin: auto;
  max-width: 100%;
}

.landingPageCardBody {
  display: flex;
  justify-content: space-between;
}

.landingPageCardBodyLeft {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.landingPageCardBodyRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.disabledLink {
  pointer-events: none;
}

.CardLogos {
  object-fit: contain;
}
