.customModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.customModalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.customModalContent {
  position: relative;
  z-index: 99;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
}
