.PQDSM-column {
    border: 1px solid black;
    padding: 10px;
    display: flex;
    text-align: center;
}

.PQDSM-column-left {
    flex: 1;
}

.PQDSM-column-right {
    flex: 4
}