.playSpan {
  text-decoration: underline;
  color: #1171ef;
  cursor: pointer;
  font-weight: 700;
}
.videoContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video {
  max-width: 100%;
  max-height: 100%;
}
