.pageContainer {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
  min-width: 100vw;
  min-height: 100vh;
}
.cardBody {
  display: flex;
  gap: 30px;
  align-items: center;
}
.playSpan {
  text-decoration: underline;
  color: #1171ef;
  cursor: pointer;
  font-weight: 700;
}
.videoContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video {
  max-width: 100%;
  max-height: 100%;
}
